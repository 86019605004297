<section class="chronos-dialog--size-xs">
  <div class="warehouse-change-modal">
    <div class="warehouse-change-modal__container">
      <div class="p-grid">
        <div class="p-col-6">
          <label [for]="'change_quantity_input'" class="warehouse-change-modal__container-label">
            {{ 'Warehouse' | translate }}
          </label>
        </div>
        <div class="p-col-6">
          <p-dropdown
            [placeholder]="'WORK_CENTER_SCREEN.DROPDOWN_DEFAULT_LABEL' | translate"
            [options]="warehouseList"
            appendTo="body"
            [dropdownIcon]="locationLoading ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-down'"
            [(ngModel)]="warehouseId"
            [style]="{ width: '100%' }"
            (onChange)="onWarehouseChange($event)"
          />
        </div>
      </div>
      <div class="p-grid" *ngIf="warehouseLocationList.length > 0">
        <div class="p-col-6">
          <label [for]="'change_quantity_input'" class="warehouse-change-modal__container-label">
            {{ 'Warehouse Location' | translate }}
          </label>
        </div>
        <div class="p-col-6">
          <p-listbox
            class="warehouse-location__input"
            [options]="warehouseLocationList"
            [optionLabel]="'label'"
            [(ngModel)]="warehouseLoationId"
            (onChange)="onWarehouseLocationChange($event.value)"
          ></p-listbox>
        </div>
      </div>
    </div>
  </div>
  <lib-modal-footer
    (submitClicked)="submitModal()"
    (declineClicked)="closeModal()"
    [whenLoadingTopic]="LOADING_TOPIC_SAVEWORKCENTER"
  ></lib-modal-footer>
</section>
