import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { WarehouseLocationListViewModel, WarehouseListViewModel, WarehouseService } from 'chronos-basedata-client';
import { GetWarehouseLocations$Params } from 'projects/chronos-basedata-client/src/lib/api/fn/ware-house/get-warehouse-locations';
import { GetWarehouses$Params } from 'projects/chronos-basedata-client/src/lib/api/fn/ware-house/get-warehouses';
import { GetWarehouseLocationsByWarehouse$Params } from 'projects/chronos-basedata-client/src/lib/api/fn/ware-house/get-warehouse-locations-by-warehouse';

@Injectable({
  providedIn: 'root'
})
export class WarehouseDsService {
  constructor(private warehouseService: WarehouseService) {}

  public getWarehouseLocations(siteId: number): Observable<WarehouseLocationListViewModel[]> {
    const params: GetWarehouseLocations$Params = {
      siteId
    };

    return this.warehouseService.getWarehouseLocations(params);
  }

  public getWarehouses(siteId: number): Observable<WarehouseListViewModel[]> {
    const params: GetWarehouses$Params = {
      siteId
    };

    return this.warehouseService.getWarehouses(params);
  }

  public getWarehouseLocationsByWarehouse(warehouseId: number): Observable<WarehouseLocationListViewModel[]> {
    const params: GetWarehouseLocationsByWarehouse$Params = {
      warehouseId
    };

    return this.warehouseService.getWarehouseLocationsByWarehouse(params);
  }
}
