import { Component, OnInit } from '@angular/core';
import { ListValue } from 'chronos-shared';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize, tap } from 'rxjs/operators';
import { notificationTopic } from '@bd/shared/utils';
import { WorkCenterListViewModel as WorkCenter } from 'chronos-basedata-client';
import { WarehouseDsService } from '@bd/core/data-services/ware-house-ds.service';

interface WarehouseLocationList {
  value: number | string;
  label: string;
  warehouseLocationName: string;
  externalWarehouseLocationId: string;
}

@Component({
  selector: 'app-change-warehouse',
  templateUrl: './change-warehouse.component.html',
  styleUrls: ['./change-warehouse.component.scss']
})
export class ChangeWarehouseComponent implements OnInit {
  public locationLoading = true;
  public warehouseList: ListValue[] = [];
  public warehouseLocationList: WarehouseLocationList[] = [];

  public readonly LOADING_TOPIC_SAVEWORKCENTER = notificationTopic.saveWorkCenterDetails;
  public warehouseId: number;
  public warehouseLoationId: number;
  private workCenter: WorkCenter;
  private workCenterCopy: WorkCenter;
  private isInputWarehouse: boolean;

  constructor(private config: DynamicDialogConfig, private ref: DynamicDialogRef, private warehouseDsService: WarehouseDsService) {}

  public ngOnInit(): void {
    this.workCenter = this.config.data?.workCenter;
    this.isInputWarehouse = this.config.data?.isInputWarehouse;
    this.getWarehouseLocation(this.workCenter.siteId);
  }

  public getWarehouseLocation(siteId?: number) {
    this.locationLoading = true;

    return this.warehouseDsService
      .getWarehouses(siteId)
      .pipe(
        tap((items) => {
          this.warehouseList = items.map((item) => ({
            label: `${item.warehouseName}`,
            value: item.warehouseId
          }));
        }),
        finalize(() => {
          this.locationLoading = false;
        })
      )
      .subscribe();
  }

  public onWarehouseChange(event: any) {
    return this.warehouseDsService
      .getWarehouseLocationsByWarehouse(event.value)
      .pipe(
        tap((items) => {
          this.warehouseLocationList = items.map((item) => ({
            label: `${item.externalWarehouseLocationId} \ ${item.warehouseLocationName}`,
            value: item.warehouseLocationId,
            warehouseLocationName: item.warehouseLocationName,
            externalWarehouseLocationId: item.externalWarehouseLocationId
          }));
        }),
        finalize(() => {
          this.locationLoading = false;
        })
      )
      .subscribe();
  }

  public onWarehouseLocationChange(event) {
    this.workCenterCopy = { ...this.workCenter };

    if (this.isInputWarehouse) {
      this.workCenterCopy.inputWarehouseLocationId = event.value;
      this.workCenterCopy.inputWarehouseLocationName = event.warehouseLocationName;
      this.workCenterCopy.inputWarehouseLocationExternalId = event.externalWarehouseLocationId;
    } else {
      this.workCenterCopy.outputWarehouseLocationId = event.value;
      this.workCenterCopy.outputWarehouseLocationName = event.warehouseLocationName;
      this.workCenterCopy.outputWarehouseLocationExternalId = event.externalWarehouseLocationId;
    }
  }

  public submitModal(): void {
    this.workCenter = this.workCenterCopy;
    this.ref.close(this.workCenter);
  }

  public closeModal(): void {
    this.ref.close();
  }
}
