/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { WorkCenterListViewModel } from '../../models/work-center-list-view-model';

export interface GetWorkCenters$Params {
  workCenterId?: number;
  searchQuery?: string;
  workCenterExternalId?: string;
  filterForActive?: boolean;
  filterForSite?: boolean;
}

export function getWorkCenters(http: HttpClient, rootUrl: string, params?: GetWorkCenters$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WorkCenterListViewModel>>> {
  const rb = new RequestBuilder(rootUrl, getWorkCenters.PATH, 'get');
  if (params) {
    rb.query('workCenterId', params.workCenterId, {});
    rb.query('searchQuery', params.searchQuery, {});
    rb.query('workCenterExternalId', params.workCenterExternalId, {});
    rb.query('filterForActive', params.filterForActive, {});
    rb.query('filterForSite', params.filterForSite, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<WorkCenterListViewModel>>;
    })
  );
}

getWorkCenters.PATH = '/api/v1/WorkCenter/GetWorkCenters';
