export enum WorkCenterColumnType {
  OperationType = 'OperationType',
  ProcessPerQuantity = 'ProcessPerQuantity',
  CounterUnit = 'CounterUnit',
  InputWarehouseLocation = 'InputWarehouseLocation',
  OutputWarehouseLocation = 'OutputWarehouseLocation',
  ShiftCalendarExternal = 'ShiftCalendarExternal',
  ActiveInActive = 'ActiveInActive',
  DeclaredPerformance = 'DeclaredPerformance'
}
