/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { BaseDataApiConfiguration } from '../base-data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getWarehouseLocations } from '../fn/warehouse/get-warehouse-locations';
import { GetWarehouseLocations$Params } from '../fn/warehouse/get-warehouse-locations';
import { getWarehouseLocationsByWarehouse } from '../fn/warehouse/get-warehouse-locations-by-warehouse';
import { GetWarehouseLocationsByWarehouse$Params } from '../fn/warehouse/get-warehouse-locations-by-warehouse';
import { getWarehouses } from '../fn/warehouse/get-warehouses';
import { GetWarehouses$Params } from '../fn/warehouse/get-warehouses';
import { WarehouseListViewModel } from '../models/warehouse-list-view-model';
import { WarehouseLocationListViewModel } from '../models/warehouse-location-list-view-model';

@Injectable({ providedIn: 'root' })
export class WarehouseService extends BaseService {
  constructor(config: BaseDataApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getWarehouses()` */
  static readonly GetWarehousesPath = '/api/v1/Warehouse/GetWarehouses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWarehouses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarehouses$Response(params?: GetWarehouses$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WarehouseListViewModel>>> {
    return getWarehouses(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWarehouses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarehouses(params?: GetWarehouses$Params, context?: HttpContext): Observable<Array<WarehouseListViewModel>> {
    return this.getWarehouses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WarehouseListViewModel>>): Array<WarehouseListViewModel> => r.body)
    );
  }

  /** Path part for operation `getWarehouseLocations()` */
  static readonly GetWarehouseLocationsPath = '/api/v1/Warehouse/GetWarehouseLocations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWarehouseLocations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarehouseLocations$Response(params?: GetWarehouseLocations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WarehouseLocationListViewModel>>> {
    return getWarehouseLocations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWarehouseLocations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarehouseLocations(params?: GetWarehouseLocations$Params, context?: HttpContext): Observable<Array<WarehouseLocationListViewModel>> {
    return this.getWarehouseLocations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WarehouseLocationListViewModel>>): Array<WarehouseLocationListViewModel> => r.body)
    );
  }

  /** Path part for operation `getWarehouseLocationsByWarehouse()` */
  static readonly GetWarehouseLocationsByWarehousePath = '/api/v1/Warehouse/GetWarehouseLocationsByWarehouse/{warehouseId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWarehouseLocationsByWarehouse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarehouseLocationsByWarehouse$Response(params: GetWarehouseLocationsByWarehouse$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WarehouseLocationListViewModel>>> {
    return getWarehouseLocationsByWarehouse(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWarehouseLocationsByWarehouse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarehouseLocationsByWarehouse(params: GetWarehouseLocationsByWarehouse$Params, context?: HttpContext): Observable<Array<WarehouseLocationListViewModel>> {
    return this.getWarehouseLocationsByWarehouse$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WarehouseLocationListViewModel>>): Array<WarehouseLocationListViewModel> => r.body)
    );
  }

}
