import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  OperationTypeListViewModel,
  ShiftCalendarListViewModel,
  WorkCenterListViewModel,
  WorkCenterService,
  UpdateWorkCentersEntry
} from 'chronos-basedata-client';
import { GetWorkCenters$Params } from 'projects/chronos-basedata-client/src/lib/api/fn/work-center/get-work-centers';
import { GetWorkCenterById$Params } from 'projects/chronos-basedata-client/src/lib/api/fn/work-center/get-work-center-by-id';
import { GetWorkCenterGroups$Params } from 'projects/chronos-basedata-client/src/lib/api/fn/work-center/get-work-center-groups';
import { GetShiftCalendars$Params } from 'projects/chronos-basedata-client/src/lib/api/fn/work-center/get-shift-calendars';
import { GetOperationTypes$Params } from 'projects/chronos-basedata-client/src/lib/api/fn/work-center/get-operation-types';
import { UpdateWorkCenters$Params } from 'projects/chronos-basedata-client/src/lib/api/fn/work-center/update-work-centers';
import { GetWorkCenterByNameOrExternalId$Params } from 'projects/chronos-basedata-client/src/lib/api/fn/work-center/get-work-center-by-name-or-external-id';

@Injectable({
  providedIn: 'root'
})
export class WorkCenterDsService {
  constructor(private workCenterService: WorkCenterService) {}

  public getWorkCenters(searchQuery?: string, filterForActive?: boolean, filterForSite?: boolean): Observable<WorkCenterListViewModel[]> {
    const params: GetWorkCenters$Params = {
      workCenterId: null,
      searchQuery,
      workCenterExternalId: null,
      filterForActive,
      filterForSite
    };

    return this.workCenterService.getWorkCenters(params);
  }

  public getWorkCenterById(workCenterId: number): Observable<WorkCenterListViewModel> {
    const params: GetWorkCenterById$Params = {
      workCenterId
    };

    return this.workCenterService.getWorkCenterById(params);
  }

  public getWorkCenterByNameOrExternalId(searchQuery: string): Observable<WorkCenterListViewModel> {
    const params: GetWorkCenterByNameOrExternalId$Params = {
      searchQuery
    };
    return this.workCenterService.getWorkCenterByNameOrExternalId(params);
  }

  public getWorkCenterGroups(): Observable<WorkCenterListViewModel[]> {
    const params: GetWorkCenterGroups$Params = {};
    return this.workCenterService.getWorkCenterGroups(params);
  }

  public getShiftCalendars(): Observable<ShiftCalendarListViewModel[]> {
    const params: GetShiftCalendars$Params = {};

    return this.workCenterService.getShiftCalendars(params);
  }

  public getOperationTypes(): Observable<OperationTypeListViewModel[]> {
    const params: GetOperationTypes$Params = {};

    return this.workCenterService.getOperationTypes(params);
  }

  public updateWorkCenters(entry: UpdateWorkCentersEntry[]): Observable<void> {
    const params: UpdateWorkCenters$Params = {
      body: entry
    };

    return this.workCenterService.updateWorkCenters(params);
  }
}
