<lib-header-layout>
  <lib-title
    [applicationName]="'TITLE.BASEDATA_APPLICATION' | translate"
    [workCenterName]="'TITLE.BASEDATA_APPLICATION_SUBTITLE' | translate"
  ></lib-title>
  <lib-info-log></lib-info-log>
  <lib-version-number [version]="version"></lib-version-number>
  <lib-user></lib-user>
  <lib-environment *ngIf="(isProductionEnvironment$ | async) === false" [message]="'HEADER.NON_PRODUCTION' | translate"></lib-environment>
</lib-header-layout>
