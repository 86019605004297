import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WorkCenterDsService } from '@bd/core/data-services/work-center-ds';
import { WorkCenterService } from '@bd/core/global-state/work-center/tasks/workcenter.service';
import { WorkCenterQuery } from '@bd/core/global-state/work-center/tasks/workcenter.query';
import { WorkCenterListViewModel } from 'chronos-basedata-client';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { nav, notificationTopic } from '@bd/shared/utils';
import { EditWorkcenterDetailsComponent } from './components/edit-workcenter-details/edit-workcenter-details.component';
import { LoadingNotificationService } from 'chronos-shared';

@Component({
  selector: 'app-work-center',
  templateUrl: './work-center.component.html',
  styleUrls: ['./work-center.component.scss']
})
export class WorkCenterComponent implements OnInit, OnDestroy {
  @ViewChild(EditWorkcenterDetailsComponent) editWorkcenterDetailsComponent!: EditWorkcenterDetailsComponent;
  public workCenterList$: Observable<WorkCenterListViewModel[]>;
  public enableEditWorkCenterSubject = new BehaviorSubject<boolean>(true);
  public enableEditWorkCenter$: Observable<boolean> = this.enableEditWorkCenterSubject.asObservable();
  public isFormValid: boolean = true;
  public loading: boolean;
  public inActiveWorkCenterFlag = false;
  public showAllSiteFlag = false;
  public workCenterNameOrExternalId: string;
  public readonly LOADING_TOPIC_SAVEWORKCENTER = notificationTopic.saveWorkCenterDetails;

  private subscriptions = new Subscription();

  constructor(
    public workCenterQuery: WorkCenterQuery,
    private workCenterDsService: WorkCenterDsService,
    private workCenterService: WorkCenterService
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(this.getWorkCenterData());
  }

  public updateEditedWorkCenters() {
    this.subscriptions.add(this.getWorkCenterData());
  }

  public resetFilters(): void {
    this.inActiveWorkCenterFlag = false;
    this.showAllSiteFlag = false;
    this.workCenterNameOrExternalId = '';

    this.getWorkCenterData();
  }

  public onFilterChange(): void {
    this.getWorkCenterData();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getWorkCenterData(): void {
    const trimmedQuery = this.workCenterNameOrExternalId?.trim();

    this.workCenterDsService.getWorkCenters(trimmedQuery, !this.inActiveWorkCenterFlag, !this.showAllSiteFlag).subscribe((workCenters) => {
      this.workCenterService.updateWorkCenters(workCenters);
    });
  }

  public save() {
    LoadingNotificationService.publish(this.LOADING_TOPIC_SAVEWORKCENTER, true);
    this.editWorkcenterDetailsComponent.onSaveAll();
    this.enableEditWorkCenterSubject.next(true);
    this.editWorkcenterDetailsComponent.onCancelEdit();
    LoadingNotificationService.publish(this.LOADING_TOPIC_SAVEWORKCENTER, false);
  }

  public editAll() {
    this.enableEditWorkCenterSubject.next(false);
    this.editWorkcenterDetailsComponent.onEditAll();
  }

  public cancel(): void {
    this.enableEditWorkCenterSubject.next(true);
    this.getWorkCenterData();
    this.editWorkcenterDetailsComponent.onCancelEdit();
  }

  public onFormValidityChange(valid: boolean) {
    this.isFormValid = valid;
  }
}
