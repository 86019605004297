import { Filters } from './workcenter.model';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { WorkCenterListViewModel } from 'chronos-basedata-client';

export interface WorKCenterState extends EntityState<WorkCenterListViewModel>, ActiveState {
  filters: Filters;
}

const initialState = {
  filters: {
    isSite: false,
    isActiveInActive: true,
    workcenterExternalId: ''
  }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'workcenters', idKey: 'workCenterId' })
export class WorkCenterStore extends EntityStore<WorKCenterState, WorkCenterListViewModel> {
  constructor() {
    super(initialState);
  }

  public updateFilters(filters: Filters): void {
    this.update({ filters });
  }

  public resetFilters(): void {
    this.update(initialState);
  }
}
