<section class="work-center page-padding">
  <header>
    <div class="work-center__container header">
      <h1 class="header__title">{{ 'BASE_DATA.WORK_CENTER_TITLE' | translate }}</h1>
      <section class="filters">
        <div class="form-group">
          <p-checkbox
            class="filters__input"
            binary="true"
            [(ngModel)]="inActiveWorkCenterFlag"
            (onChange)="onFilterChange()"
            [label]="'WORK_CENTER_SCREEN.ACTIVE_INACTIVE' | translate"
          ></p-checkbox>
        </div>
        <div class="form-group">
          <p-checkbox
            class="filters__input"
            binary="true"
            [(ngModel)]="showAllSiteFlag"
            (onChange)="onFilterChange()"
            [label]="'WORK_CENTER_SCREEN.SITES' | translate"
          ></p-checkbox>
        </div>
        <div class="form-group text-search">
          <input
            id="textSearch"
            type="text"
            class="search-bar"
            pInputText
            [(ngModel)]="workCenterNameOrExternalId"
            [placeholder]="'WORK_CENTER_SCREEN.SEARCH_TEXT' | translate"
            (input)="onFilterChange()"
          />
        </div>
        <div class="form-control">
          <lib-button [label]="'WORK_CENTER_SCREEN.RESET'" (clicked)="resetFilters()"></lib-button>
        </div>
      </section>
    </div>
  </header>
  <div *ngIf="(workCenterQuery.isLoading$ | async) === false; else loading">
    <app-edit-workcenter-details
      [workCenters$]="workCenterQuery.allWorkCenters$"
      (updateWorkCenterEvent)="updateEditedWorkCenters()"
      (formValidityChange)="onFormValidityChange($event)"
    ></app-edit-workcenter-details>
  </div>
</section>

<ng-template #loading>
  <lib-loading-indicator></lib-loading-indicator>
</ng-template>

<div *ngIf="enableEditWorkCenter$ | async; else saveWorkCenter" class="work-center-config-footer">
  <div class="work-center__footer p-grid p-nogutter">
    <div class="work-center__footer__leftcorner-wrapper">
      <lib-button
        (clicked)="editAll()"
        [label]="'WORK_CENTER_SCREEN.EDIT_WORKCENTER' | translate"
        [typeClass]="'button--primary'"
        [sizeClass]="'button--small'"
      ></lib-button>
    </div>
  </div>
</div>

<ng-template #saveWorkCenter>
  <div class="work-center__footer p-grid p-nogutter work-center-config-footer">
    <div class="work-center__footer__leftcorner-wrapper">
      <lib-button
        class="work-center__footer--margin-right"
        (clicked)="cancel()"
        [label]="'WORK_CENTER_SCREEN.CANCEL' | translate"
        [typeClass]="'button--secondary'"
        [sizeClass]="'button--small'"
      ></lib-button>
      <lib-button
        (clicked)="save()"
        [label]="'WORK_CENTER_SCREEN.SAVE' | translate"
        [typeClass]="'button--primary'"
        [sizeClass]="'button--small'"
        [loadingTopic]="LOADING_TOPIC_SAVEWORKCENTER"
        [disabled]="!isFormValid"
      ></lib-button>
    </div>
  </div>
</ng-template>
